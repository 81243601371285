export const multilingual = {
    english: {
        layout: {
            menuItem1: 'Homepage',
            menuItem2: 'Plan Investment',
            menuItem3: 'Energy Measures Admin Page',
            menuItem4: 'My Profile',
            menuItem5: 'Sign Out',
            language: 'Language',
            welcome: 'Welcome',
            copyright: `Copyright I-NERGY Consortium @${new Date().getFullYear()} All rights reserved`,
            eu_msg: 'Co-funded by the Horizon 2020 Framework Programme of the European Union Under grant agreement No 101016508',
        },
        homepage: {
            serviceTitle1: 'Issue your virtual EPC',
            serviceDesc1: 'Energy Performance Certificate quickly and easily via a user intuitive UI.',
            serviceTitle2: 'Visualize energy savings',
            serviceDesc2: 'Charts and useful information about your energy savings.',
        },
        planInvestment: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'Plan Investment',
            pageTitle: 'Energy Efficiency Investment De-Risking',
            configuration: 'Configuration',
            sectionTitle1: 'Select Building Address',
            sectionDesc1: 'Fill in the dropdown menu with the proper address to open the map.',
            sectionPlaceholder1: 'Address',
            sectionTitle2: 'Choose a resembling photo',
            sectionDesc2: 'Select the image that looks like your building the most. In this way, the system will understand its characteristics.',
            sectionTitle3: 'Building Location & Surroundings',
            sectionDesc3: 'A map displaying the chosen building\'s location.',
            sectionWarn3: 'Please select an address first!',
            sectionTitle4: 'Building Materials',
            sectionDesc4: 'Select heavy (solid brick, hollow reinforced concrete panel) or light (wood and wooden) building materials.',
            sectionTitleHeavy: 'Heavy building materials',
            sectionTitleLight: 'Light building materials',
            perimeter: 'Perimeter',
            button: 'Virtual EPC',
        },
        selectInvestment: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'Plan Investment',
            breadcrumb3: 'Virtual EPC & Innovation Measures',
            pageTitle: 'Energy Efficiency Investment De-Risking',
            general: 'General information',
            clickDetails: 'Click for details',
            energyClass: 'Energy Class',
            totalConsumption: 'Total Energy Consumption',
            year: 'year',
            measurement1: 'Mean energy consumption (2017-2020):',
            barChartTitle: 'All buildings\' mean energy consumption (kwh) 2017-2020',
            barChartLabel: 'Available data',
            measurement2: 'Building\'s heat losses (2017-2020)',
            pieChartLabel: '% of heat losses',
            measures: 'Energy Efficiency Measures',
            totalCost: 'Total Cost',
            expected: 'Expected Results',
            tryOther: 'Want to try other measures?',
            yesModal: 'Yes, try other measures.',
            noModal: 'No, back to service page.',
            button: 'Calculate',
        },
        adminPage: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'Energy Measures Admin page',
            pageTitle: 'Energy Efficiency Investment De-Risking',
            rows: 'Rows per page',
            save: 'SAVE',
            reset: 'RESET',
            delete: 'DELETE',
            addNewMeasure: 'Add New Measure',
            code: 'Code',
            unit: 'Unit',
            lambda: 'Lambda (λ)',
            changeLambda: 'Change lambda',
            totalCost: 'Total cost',
            changeTotalCost: 'Change total cost',
            editSuccess: 'Energy Measure has been successfully changed!',
            editFailure: 'Something went wrong! Please try again.',
            deleteSuccess: 'Energy Measure has been successfully deleted!',
            deleteFailure: 'Something went wrong! Please try again.'
        },
        myProfile: {
            breadcrumb1: 'Homepage',
            breadcrumb2: 'User Profile',
            current_user: 'Currently logged in user',
            clickDetails: 'Click for details',
            username: 'Username',
            roles: 'Roles',
            first_name: 'First Name',
            last_name: 'Last Name',
            expand: 'Click to expand',
            noRoles: 'No roles assigned',
            role_singular: 'role',
            role_plural: 'roles'
        },
    },
    latvian: {
        layout: {
            menuItem1: 'Mājas lapa',
            menuItem2: 'Pasākumu plānošana',
            menuItem3: 'Energoefektivitātes pasākumu administratora sadaļa',
            menuItem4: 'Mans profils',
            menuItem5: 'Izrakstīties',
            language: 'Valoda',
            welcome: 'Laipni lūdzam',
            copyright: `Autortiesības I-NERGY konsorcijs @${new Date().getFullYear()} Visas tiesības aizsargātas`,
            eu_msg: 'Līdzfinansē Eiropas Savienības pamatprogramma "Apvārsnis 2020" saskaņā ar dotāciju nolīgumu Nr. 101016508.',
        },
        homepage: {
            serviceTitle1: 'Izsniedziet savu virtuālo Ēkas energosertifikātu',
            serviceDesc1: 'Energoefektivitātes sertifikāts ātri un vienkārši, izmantojot lietotājam draudzīgu lietotāja saskarni.',
            serviceTitle2: 'Enerģijas ietaupījuma vizualizēšana',
            serviceDesc2: 'Diagrammas un noderīga informācija par enerģijas ietaupījumu.',
        },
        planInvestment: {
            breadcrumb1: 'Mājas lapa',
            breadcrumb2: 'Plānot ieguldījumus',
            pageTitle: 'Investīciju riska mazināšana uzlabojot  energoefektivitāti',
            configuration: 'Konfigurācija',
            sectionTitle1: 'Atlasiet ēkas adresi',
            sectionDesc1: 'Lai atvērtu karti, nolaižamajā izvēlnē ievadiet pareizo adresi.',
            sectionPlaceholder1: 'Adrese',
            sectionTitle2: 'Izvēlieties līdzīgu fotoattēlu',
            sectionDesc2: 'Izvēlieties attēlu, kas visvairāk atgādina jūsu ēku. Tādējādi sistēma sapratīs ēkas rakstumlielumus.',
            sectionTitle3: 'Ēkas atrašanās vieta un apkārtne',
            sectionDesc3: 'Karte, kurā redzama izvēlētās ēkas atrašanās vieta.',
            sectionWarn3: 'Lūdzu, vispirms izvēlieties adresi!',
            sectionTitle4: 'Celtniecības materiāli',
            sectionDesc4: 'Izvēlieties smagos (akmens, ķieģelis, dzelzsbetona paneļi vai citi līdzīgi materiāli) vai vieglos (koka materiāli) būvniecības materiālus.',
            sectionTitleHeavy: 'Smagie būvniecības materiāli',
            sectionTitleLight: 'Vieglie būvniecības materiāli',
            perimeter: 'Perimetrs',
            button: 'VIRTUĀLAIS ĒKAS ENERGOSERTIFIKĀTS',
        },
        selectInvestment: {
            breadcrumb1: 'Mājas lapa',
            breadcrumb2: 'Plānot ieguldījumus',
            breadcrumb3: 'Virtuālais ēkas energosertifikāts un inovāciju pasākumi',
            pageTitle: 'Energoefektivitātes investīciju risku mazināšana',
            general: 'Vispārīga informācija',
            clickDetails: 'Noklikšķiniet, lai iegūtu detaļas',
            energyClass: 'Energoefektivitātes klase',
            totalConsumption: 'Kopējais enerģijas patēriņš',
            year: 'gadā',
            measurement1: 'Vidējais enerģijas patēriņš (2017-2020):',
            barChartTitle: 'Ēkas vidējais enerģijas patēriņš (kwh) (2017-2020)',
            barChartLabel: 'Pieejamie dati',
            measurement2: 'Ēkas siltuma zudumi (2017-2020)',
            pieChartLabel: '% no siltuma zudumiem',
            measures: 'Energoefektivitātes pasākumi',
            totalCost: 'Kopējās izmaksas',
            expected: 'Gaidāmie rezultāti',
            tryOther: 'Vai vēlaties pamēģināt citus pasākumus?',
            yesModal: 'Jā, izmēģiniet citus pasākumus.',
            noModal: 'Nē, atgriezieties pie pakalpojumu lapas.',
            button: 'Aprēķināt',
        },
        adminPage: {
            breadcrumb1: 'Mājas lapa',
            breadcrumb2: 'Energoefektivitātes pasākumu administratora sadaļa',
            pageTitle: 'Energoefektivitātes investīciju risku mazināšana',
            rows: 'Rindas vienā lapā',
            save: 'Saglabāt',
            reset: 'Atjaunot',
            delete: 'Dzēst',
            addNewMeasure: 'Pievienot jaunu pasākumu',
            code: 'Kods',
            unit: 'Vienība',
            lambda: 'Lambda (λ)',
            changeLambda: 'Mainīt lambda',
            totalCost: 'Kopējās izmakas',
            changeTotalCost: 'Mainiet kopējās izmaksas',
            editSuccess: 'Enerģijas pasākums ir veiksmīgi nomainīts!',
            editFailure: 'Kaut kas nogāja greizi! Lūdzu mēģiniet vēlreiz.',
            deleteSuccess: 'Enerģijas pasākums ir veiksmīgi izdzēsts!',
            deleteFailure: 'Kaut kas nogāja greizi! Lūdzu mēģiniet vēlreiz.'
        },
        myProfile: {
            breadcrumb1: 'Mājas lapa',
            breadcrumb2: 'Lietotāja profils',
            current_user: 'Aktīvais lietotājs',
            clickDetails: 'Noklikšķiniet, lai iegūtu detaļas',
            username: 'Lietotājvārds',
            roles: 'Lomas',
            first_name: 'Vārds',
            last_name: 'Uzvārds',
            expand: 'Noklikšķiniet, lai paplašinātu',
            noRoles: 'Nav piešķirtu lomu',
            role_singular: 'Loma',
            role_plural: 'Lomas'
        },
    }
}