import Keycloak from "keycloak-js";

// local configuration
// const my_keycloak = new Keycloak({
//     "realm": "INERGY",
//     "url": "https://keycloak.inergy.epu.ntua.gr/",
//     "clientId": "inergy_uc13_local"
// })

// ICCS deployment configuration
const my_keycloak = new Keycloak({
    "realm": "INERGY",
    "url": "https://keycloak.inergy.epu.ntua.gr/",
    "clientId": "inergy_uc13"
})

export default my_keycloak